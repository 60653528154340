<template>
  <nav ref="navBar" class="nav-bar">
    <div class="left-menu">
      <div v-if="!isSearchOpen">
        <button v-if="isMenuOpen" class="icon-button" @click="toggleMenu">
          <svg-icon type="mdi" :path="mdiClose"></svg-icon>
        </button>
        <button v-else class="icon-button" @click="toggleMenu">
          <svg-icon type="mdi" :path="mdiMenu"></svg-icon>
        </button>
      </div>
    </div>

    <div class="center-title">
      <router-link to="/" class="home-link">hentai-tok</router-link>
    </div>

    <div class="right-menu">
      <div v-if="!isMenuOpen">
        <button v-if="isSearchOpen" class="icon-button" @click="toggleSearch">
          <svg-icon type="mdi" :path="mdiClose"></svg-icon>
        </button>
        <button v-else class="icon-button" @click="toggleSearch">
          <svg-icon type="mdi" :path="mdiMagnify"></svg-icon>
        </button>
      </div>
    </div>
  </nav>

  <div v-if="isMenuOpen" :style="{ top: navHeight + 'px' }" class="overlay menu-overlay">
    <div class="overlay-content menu-content">
      <p>Menu is open</p>
    </div>
  </div>

  <div v-if="isSearchOpen" :style="{ top: navHeight + 'px' }" class="overlay search-overlay">
    <div class="overlay-content search-content">
      <SearchTags/>
    </div>
  </div>

  <div class="content-wrapper">
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Center title style */
.center-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.home-link {
  font-size: 1.2em;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.home-link:hover {
  color: #555;
}

/* Adjust left-menu and right-menu for centering */
.left-menu, .right-menu {
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  left: 0;
  width: 100vw;
  height: calc(100vh - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-height: 80vh;
  overflow-y: auto;
}

.content-wrapper {
  padding-top: 60px;
}

router-view {
  position: relative;
}

</style>

<script>

import { defineComponent } from 'vue';

// Components
import SearchTags from '@/components/SearchTags.vue';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMagnify, mdiClose, mdiMenu } from '@mdi/js';

export default defineComponent({
  components: {
    SvgIcon,
    SearchTags,
  },
  data() {
    return {
      isMenuOpen: false,
      isSearchOpen: false,
      navHeight: 0,
      mdiMagnify,
      mdiClose,
      mdiMenu,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
  },
  mounted() {
    this.navHeight = this.$refs.navBar.offsetHeight;
  },
});
</script>
