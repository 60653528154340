<template>
  <div>
    <div class="button-container">
      <button @click="search">検索</button>
    </div>
    <div v-for="category in tags" :key="category.category">
      <h2>{{ category.category }}</h2>
      <div class="tag-container">
        <div v-for="tag in category.tags" :key="tag.genre_id">
          <span :class="{ 'selected-tag': isSelected(tag.genre_id), 'tag': true }" @click="toggleTag(tag.genre_id)">{{ tag.genre_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h2 {
  margin-bottom: 10px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap; /* タグが幅を超えると改行するように設定 */
  gap: 10px; /* タグの間にスペースを追加 */
  row-gap: 20px;
  margin-bottom: 10px; /* カテゴリごとにスペースを追加 */
}

.selected-tag {
  background-color: yellow;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid black;
}

span {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-container button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container button:hover {
  background-color: #45a049;
}
</style>

<script>
import searchableTags from "@/assets/tags/searchableTags.json";

export default {
  data() {
    return {
      tags: searchableTags["searchableTags"],
      selectedTags: [],
    };
  },
  methods: {
  toggleTag(tagId) {
    if (this.isSelected(tagId)) {
      this.selectedTags = this.selectedTags.filter(id => id !== tagId);
    } else {
      this.selectedTags.push(tagId);
    }
  },
  isSelected(tagId) {
    return this.selectedTags.includes(tagId);
  },
  search() {
    const param = this.selectedTags.join('-');
    this.$router.push({ path: "/video", query: { tags: param } })
      .then(() => {
        window.location.reload();  // 強制的にページを再読み込み
      })
      .catch((err) => {
        console.error(err);
      });
  },
}

};
</script>
